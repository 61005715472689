import React from 'react';
import LoginPage from '../Components/Login_Signup/LoginForm'

const Login = () => {
  return (
    <div>
    <LoginPage/>
    </div>
  )
}

export default Login;