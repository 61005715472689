import React from 'react';
import SignupForm from '../Components/Login_Signup/SignupForm';


const Signup = () => {
  return (
    <div>
    <SignupForm/>
    </div>
  )
}

export default Signup;